import { faBuildings } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack, Typography } from "@mui/joy";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import { CustomBrandingLogo } from "components";
import { DataRoomCreateButton } from "features/dataRoom";
import { useCurrentUserOrganizationBranding, useUserRole } from "hooks";

interface WelcomeBlockProps {
  dataRoomsCount: number;
}

const WelcomeBlock = memo<WelcomeBlockProps>(({ dataRoomsCount }) => {
  const { domain, logoBase64, organizationName } =
    useCurrentUserOrganizationBranding();
  const { isDecentriqAdmin, isOrganizationAdmin } = useUserRole();
  return (
    <Stack
      gap={1}
      height="100%"
      padding={2}
      sx={({ palette, radius }) => ({
        background: palette.primary[50],
        borderRadius: radius.sm,
      })}
    >
      <Stack alignItems="center" direction="row">
        {logoBase64 ? (
          <CustomBrandingLogo
            domain={domain}
            fallbackToDecentriq={false}
            logoBase64={logoBase64}
            maxHeight="24px"
            withPoweredBy={false}
          />
        ) : (
          <FontAwesomeIcon fixedWidth={true} icon={faBuildings} />
        )}
        <Typography level="body-sm" textColor="inherit">
          {organizationName}
        </Typography>
      </Stack>
      <Typography level="h4">Welcome to the Decentriq Platform!</Typography>
      <Typography level="body-md" mb={1}>
        {dataRoomsCount === 0
          ? "Start by customising your organisation, inviting your team members and create your first data clean room."
          : "Continue managing your data clean rooms, collaborating with your team and partners."}
      </Typography>
      <Stack direction="row" mt="auto">
        {(isDecentriqAdmin || isOrganizationAdmin) && (
          <Button
            color="primary"
            component={NavLink}
            startDecorator={
              <FontAwesomeIcon fixedWidth={true} icon={faBuildings} />
            }
            to="/admin"
            variant="solid"
          >
            Admin portal
          </Button>
        )}
        <DataRoomCreateButton />
      </Stack>
    </Stack>
  );
});

WelcomeBlock.displayName = "WelcomeBlock";

export default WelcomeBlock;
